import { start, registerApplication } from 'single-spa'
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from 'single-spa-layout'
import * as containerLoader from './loaders/container'
import * as headerLoader from './loaders/header'

const loadingFns: LoadFunctions = {
  '@creditas/aulr-complementary': () => import('@creditas/aulr-complementary'),
  '@creditas/aulr-header': () => import('@creditas/aulr-header'),
  '@creditas/aulr-root': () => import('./index.singlespa'),
}

const configLayoutData = {
  loaders: {
    container: containerLoader,
    header: headerLoader,
  },
  props: {},
}

const routes = constructRoutes(
  document.querySelector('#single-spa-template') as Element,
  configLayoutData
)

const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return loadingFns[name]()
  },
})

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
  active: false,
})

applications.forEach(registerApplication)

if (!window.location.pathname || window.location.pathname === '/') {
  window.singleSpaNavigate('/')
}

layoutEngine.activate()
start()
