import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import singleSpaReact from 'single-spa-react'

const Loader = (): JSX.Element => {
  return (
    <ContentLoader
      speed={2}
      width={476}
      height={50}
      viewBox="0 0 476 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: '100%' }}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100" height="48" />
    </ContentLoader>
  )
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Loader,
})

export const bootstrap = reactLifecycles.bootstrap

export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
